<template>
    <a-card :bordered="false">
        <div class="table-operator">
            <a-button type="primary" icon="plus" @click="goEdit(0)">新建</a-button>
            <a-button type="default" icon="left" @click="goBack()" style="float:right">返回</a-button>
        </div>
        <s-table
                ref="table"
                size="default"
                rowKey="key"
                :columns="columns"
                :data="loadData"
                :alert="false"
                showPagination="true"
        >
        <span slot="description" slot-scope="text">
          <ellipsis :length="40" tooltip>{{ text }}</ellipsis>
        </span>
            <span slot="centerId" slot-scope="text, record">
          {{ record.centerId===0 ? "系统" : "用户" }}
        </span>
            <span slot="action" slot-scope="text, record">
          <template>
            <a @click="goView(record.id)">查看</a>
            <a-divider type="vertical" v-if="queryParam.centerId==record.centerId"/>
            <a @click="goEdit(record.id)" v-if="queryParam.centerId==record.centerId">编辑</a>
            <a-divider type="vertical"  v-if="queryParam.centerId==record.centerId"/>
            <a @click="delItem(record.id)" v-if="queryParam.centerId==record.centerId">删除</a>
          </template>
        </span>
        </s-table>
    </a-card>
</template>

<script>
    import { STable, Ellipsis } from '@/components'
    import { getFastList, deleteFast, addFast } from '@/api/quality/item'

    const columns = [
        {
            title: '序号',
            dataIndex: 'index',
            width: '10%',
        },
        {
            title: '名称',
            dataIndex: 'name',
            width: '30%',
        },
        {
            title: '描述',
            dataIndex: 'description',
            width: '20%',
            scopedSlots: { customRender: 'description' }
        },
        {
            title: '创建人',
            dataIndex: 'centerId',
            width: '20%',
            scopedSlots: { customRender: 'centerId' }
        },
        {
            title: '操作',
            dataIndex: 'action',
            width: '20%',
            scopedSlots: { customRender: 'action' }
        }
    ]

export default {
    name: 'TableList',
    components: {
        STable,
        Ellipsis
    },
    data () {
        this.columns = columns
        return {
            // 查询参数
            queryParam: {
                centerId: 0
            },
            // 加载数据方法 必须为 Promise 对象
            loadData: parameter => {
                const requestParameters = Object.assign({}, parameter, this.queryParam)
                console.log('loadData request parameters:', requestParameters)
                return getFastList(requestParameters)
                    .then(res => {

                        for (var i = 0;i<res.data.list.length;i++) {
                            res.data.list[i].index = ((parameter.pageNumber - 1)* parameter.pageSize) + i + 1
                        }

                        var data = {
                            data: res.data.list,
                            pageNo: parameter.pageNumber,
                            pageSize: parameter.pageSize,
                            totalCount: res.data.total,
                            totalPage: parseInt(res.data.total / parameter.pageSize) + 1
                        }
                        return data
                    })
            }
        }
    },
    created () {
        // 读出用户所属单位
        let area = JSON.parse(localStorage.getItem("areaValue"));
        this.queryParam.centerId = area.depId

    },
    methods: {
        // 操作方法
        goView (id) {
            this.$router.push({
                path: '/test/setting/view',
                query: {
                    id: id
                }
            })
        },
        goEdit (id) {
            if (id === 0) {
                var formData = {
                    centerId: this.queryParam.centerId,
                    description: '',
                    fastConfigurationInfoEntities: [],
                    name: ''
                }
                addFast(formData).then(response => {
                    this.$router.push({
                        path: '/test/setting/edit',
                        query: {
                            id: response.data
                        }
                    })
                })
            } else {
                this.$router.push({
                    path: '/test/setting/edit',
                    query: {
                        id: id
                    }
                })
            }
        },
        goBack () {
            this.$router.back()
        },
        delItem (id) {
            var self = this
            this.$confirm({
                title: '确认删除',
                content: `是否确认删除当前快速配置项？`,
                cancelText: '取消',
                okText: '确定',
                okType: 'danger',
                onOk () {
                    return new Promise((resolve, reject) => {
                        deleteFast({ id: id }).then(response => {
                            self.$message.error('删除成功')
                            self.loadData(self.queryParam)
                            self.$refs.table.refresh(true)
                        }).catch((e) => {
                            self.$message.error('删除成功')
                        }).finally(() => {
                            resolve()
                        })
                    })
                }
            })
        }
    }
}
</script>
